import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  Linking,
  SafeAreaView,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import styles from "./style";

export default function ConsultaCaso1I({ navigation }) {
  const auth = getAuth();
  const user = auth.currentUser;
  const [email, setEmail] = useState("");

  const [retorno, setRetorno] = useState([]);

  useEffect(() => {
    if (user !== null) {
      user.providerData.forEach((profile) => {
        setEmail(profile.email);
      });
    }
  }, []);

  async function Caso1() {
    const db = getFirestore();
    const citiesRef = collection(db, "Resumos");
    const q = query(
      citiesRef,
      where("numeroCaso", "==", "1"),
      where("email", "==", email),
      where("ifoUsuario.diplo", "not-in", [
        "COE",
        "DAO",
        "Formula",
        "Intercept",
      ])
    );
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const Obj = {
        id: doc.id,
        email: doc.data().email,
        status: doc.data().status,
        Anamnesis: doc.data().Anamnesis,
        CefalometriaPaciente: doc.data().CefalometriaPaciente,
        DocPaciente: doc.data().DocPaciente,
      };
      array.push(Obj);
    });

    setRetorno(array);
  }

  useEffect(() => {
    Caso1();
  }, [email]);

  return (
    <LinearGradient style={styles.container} colors={["#054A59", "#0D0D0D"]}>
      <SafeAreaView>
        <View style={styles.header}>
          <View>
            <Text style={styles.Title}>Consultation 1st Clinical Case</Text>
          </View>

          <TouchableOpacity
            style={styles.buttomMenu}
            onPress={() => navigation.goBack()}
          >
            <MaterialCommunityIcons
              name="reply"
              size={40}
              color="#fff"
              style={{ justifyContent: "center", alignContent: "center" }}
            />
          </TouchableOpacity>
        </View>

        <ScrollView style={{ flex: 1 }}>
          {retorno &&
            retorno.map((item, index) => {
              return (
                <View key={index}>
                  <Text style={styles.descricao}>
                    If you have any questions, please send a message: +55 14
                    99620-7009
                  </Text>

                  {item.status !== "Concluido" ? (
                    <Text style={styles.textStatus}>
                      Status: 🟠 {item.status === "Esperando validación de soporte" && "Waiting for support validation"}
                      {item.status === "Reprobado por los profesores" && "Failed by teachers"}
                      {item.status === "Reprobado por el soporte" && "Failed by support"}
                      {item.status === "Esperando análisis de los profesores" && "Waiting for analysis from teachers"}
                      {item.status === "Planejamento en curso" && "Planning in progress"}
                    </Text>
                  ) : (
                    <Text style={styles.textStatus}>
                      Status: 🟢 {item.status === "Concluido" && "Completed"}
                    </Text>
                  )}

                  {item.status === "Concluido" && (
                    <Text style={styles.descricao}>
                      Check your email to see the resolution of your clinical
                      case
                    </Text>
                  )}

                  <Text style={styles.descricao3}>
                    Attach the Anamnesis Guide
                  </Text>

                  <TouchableOpacity
                    style={styles.buttomFoto}
                    onPress={() => {
                      Linking.openURL(item.Anamnesis);
                    }}
                  >
                    <MaterialCommunityIcons
                      name="file-export"
                      size={24}
                      color="#000"
                    />

                    <Text style={styles.textBotao}>View file</Text>
                  </TouchableOpacity>

                  <Text style={styles.descricao4}>
                    Attach the patient's complete documentation (PowerPoint)
                  </Text>

                  <TouchableOpacity
                    style={styles.buttomFoto}
                    onPress={() => {
                      Linking.openURL(item.DocPaciente);
                    }}
                  >
                    <MaterialCommunityIcons
                      name="file-export"
                      size={24}
                      color="#000"
                    />

                    <Text style={styles.textBotao}>View file</Text>
                  </TouchableOpacity>

                  <Text style={styles.descricao4}>
                    Attach the patient's cephalometry (PDF - WebCeph)
                  </Text>

                  <TouchableOpacity
                    style={styles.buttomFoto}
                    onPress={() => {
                      Linking.openURL(item.CefalometriaPaciente);
                    }}
                  >
                    <MaterialCommunityIcons
                      name="file-export"
                      size={24}
                      color="#000"
                    />

                    <Text style={styles.textBotao}>View file</Text>
                  </TouchableOpacity>

                  {item.status === "Reprobado por el soporte" ? (
                    <TouchableOpacity
                      style={styles.buttomEnviar}
                      onPress={async () => {
                        const db = getFirestore();
                        await deleteDoc(doc(db, "Resumos", item.id)),
                          navigation.navigate("Caso1I");
                      }}
                    >
                      <Text style={{ color: "#000000", textAlign: "center" }}>
                        Send again
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <View></View>
                  )}

                  {item.status === "Reprobado por los profesores" ? (
                    <TouchableOpacity
                      style={styles.buttomEnviar}
                      onPress={async () => {
                        const db = getFirestore();
                        await deleteDoc(doc(db, "Resumos", item.id)),
                          navigation.navigate("Caso1I");
                      }}
                    >
                      <Text style={{ color: "#000000", textAlign: "center" }}>
                        Send again
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <View></View>
                  )}
                </View>
              );
            })}
          ;
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}
